<template>
	<div class="text-center">
		<h4 v-if="loading">Loading Service Request...</h4>
		<h4 v-if="error">There was an error. SRF ID:{{ id }}</h4>
	</div>
</template>

<script>
import generateSrfPdf from '@/view/content/lib/srfPDF';

export default {
	name: 'SrfDownload',
	props: ['id'],
	data() {
		return {
			loading: false,
			error: false,
		};
	},
	created() {
		if (this.id) {
			this.loading = true;
			this.$http
				.get(`srf/${this.id}`)
				.then(resp => {
					generateSrfPdf(resp.data.data.data);
					this.$router.push({ name: 'srflist' });
				})
				.catch(err => {
					this.error = true;
				})
				.finally(() => {
					this.loading = true;
				});
		}
	},
};
</script>
